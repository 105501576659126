import { SaisieChoixServices } from '../../models/form-state.model';

export interface ServiceState {
  saisieChoixServices: SaisieChoixServices;
  isValid: boolean;
}

export const initialState: ServiceState = {
  saisieChoixServices: {
    EnvAttB: false,
    EnvoiAttestationBailleur: {
      TypeBailleur: undefined,
      BailleurPrive: undefined,
      BailleurSocial: undefined,
      isValid: false
    },
    ResAncAss: false,
    ResiliationAncienneAssurance: {
      Raison: undefined,
      DemenagementPlusUnAn: undefined,
      NomAssureur: '',
      AddressAssureur: '',
      CodePostalAssureur: '',
      VilleAssureur: '',
      EmailAssureur: '',
      NumeroContrat: '',
      MoisDemarrageContrat: '',
      DateEnvoiLR: '',
      isValid: false
    },
    OppositionMarketing: false
  },
  isValid: false
};
