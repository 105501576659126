import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppResource } from 'src/app/app.resource';
import { CancellationVM } from 'src/app/shared/models/components/cancellationVm';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { CancellationEnum } from 'src/app/shared/models/enum/cancellation.enum';
import { State } from 'src/app/core/state/core.state';
import * as fromService from '../../../core/state/service';
import * as fromInfo from 'src/app/core/state/info';
import { Store } from '@ngrx/store';
import { Raison } from 'src/app/core/models/form-state.model';
import { MatomoTracker } from 'ngx-matomo-client';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import { lastValueFrom, take } from 'rxjs';
import { Stepper } from 'src/app/core/models/stepper.model';
import { InsurerModel } from 'src/app/api/models/insurer.model';
import { environment } from 'src/environments/environment';
import { InsurerService } from 'src/app/api/services/insurer.service';
import { CANCELATION_DELAY } from 'src/app/shared/constants/cancelation.constants';
interface Insurance {
  text: string;
  value: string;
}

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CancellationComponent extends BaseComponent implements OnInit {
  headerStep!: HeaderVM;
  override question!: QuestionVM;
  cancellationEnum = CancellationEnum;

  displayMoveInModal: boolean = false;
  displayLessOneYearModal: boolean = false;
  displayMoreOneYearModal: boolean = false;
  displayModal: boolean = false;

  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  monthStarting: string | undefined;

  insurerNotFind: boolean = true;

  //Form
  insurerChange: boolean = false;
  moveIn: boolean = false;
  lessOneYear: boolean = false;
  moreOneYear: boolean = false;

  otherInsurer: boolean = false;
  cancellationForm!: FormGroup;

  cancellationVm!: CancellationVM;

  insurances: Insurance[] = [];
  selectedInsurance!: Insurance | undefined;
  adressesAssureurs: InsurerModel[] | undefined;

  urlModeleCourrier = environment.modeleCourrierResiliation;

  get insurer() {
    return this.cancellationForm.get('insurer');
  }

  get other() {
    return this.cancellationForm.get('other');
  }

  get address() {
    return this.cancellationForm.get('address');
  }

  get postalCode() {
    return this.cancellationForm.get('postalCode');
  }

  get city() {
    return this.cancellationForm.get('city');
  }

  get email() {
    return this.cancellationForm.get('email');
  }

  get contractNumber() {
    return this.cancellationForm.get('contractNumber');
  }

  get startingContract() {
    return this.cancellationForm.get('startingContract');
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private insurerService: InsurerService
  ) {
    super(store, resources, tracker);

    this.headerStep = new HeaderVM(
      this.resource.header.stepContract,
      3,
      this.resource.header.subStepResiliation,
      7
    );

    this.question = new QuestionVM(this.resource.question.resiliation1);

    this.insurerService.GetInsurers().subscribe({
      next: response => {
        if (response) {
          this.adressesAssureurs = response;
          this.adressesAssureurs.map((m, id) => {
            this.insurances.push({
              text: m.name,
              value: id.toString()
            });
          });
        }
      },
      error: err => {
        console.error(err);
      }
    });

    this.insurances.push({ text: 'Autre', value: 'OTR' });

    this.cancellationVm = new CancellationVM('');
  }

  override async ngOnInit() {
    super.ngOnInit();

    this.initForm();

    this.insurer?.valueChanges.subscribe((value: any) => {
      if (value?.value === 'OTR') {
        this.other?.setValidators(Validators.required);
        this.otherInsurer = true;
      } else {
        this.other?.clearValidators();
        this.otherInsurer = false;
        this.insurerNotFind = true;
      }

      this.other?.updateValueAndValidity();
    });

    this.loadStateData();
  }

  private initForm() {
    this.cancellationForm = new FormGroup(
      {
        insurer: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        other: new FormControl<string | null>(null, {}),
        address: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        postalCode: new FormControl<string | null>(null, {
          validators: [Validators.required, Validators.pattern('^[0-9]{5}$')],
          nonNullable: true
        }),
        city: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        email: new FormControl<string | null>(null, {}),
        contractNumber: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        startingContract: new FormControl<string | null>(null, {})
      },
      {
        updateOn: 'change'
      }
    );
  }

  private loadStateData() {
    this.store.select(fromService.selectService).subscribe(res => {
      if (res.saisieChoixServices.ResiliationAncienneAssurance?.Raison == Raison.ChangementAssureur)
        this.insurerChange = true;
      else if (res.saisieChoixServices.ResiliationAncienneAssurance?.Raison == Raison.Emmenagement)
        this.moveIn = true;

      if (res.saisieChoixServices.ResiliationAncienneAssurance?.DemenagementPlusUnAn != undefined) {
        if (res.saisieChoixServices.ResiliationAncienneAssurance?.DemenagementPlusUnAn)
          this.moreOneYear = true;
        else if (!res.saisieChoixServices.ResiliationAncienneAssurance?.DemenagementPlusUnAn)
          this.lessOneYear = true;
      }

      this.selectedInsurance = this.insurances.find(obj => {
        return obj.text === res.saisieChoixServices.ResiliationAncienneAssurance?.NomAssureur;
      });

      if (
        this.selectedInsurance == undefined &&
        res.saisieChoixServices.ResiliationAncienneAssurance?.NomAssureur != ''
      ) {
        this.selectedInsurance = this.insurances.find(obj => {
          return obj.value === 'OTR';
        });
        this.other?.setValue(res.saisieChoixServices.ResiliationAncienneAssurance?.NomAssureur);
      }

      this.address?.setValue(res.saisieChoixServices.ResiliationAncienneAssurance?.AddressAssureur);

      this.postalCode?.setValue(
        res.saisieChoixServices.ResiliationAncienneAssurance?.CodePostalAssureur
      );

      this.city?.setValue(res.saisieChoixServices.ResiliationAncienneAssurance?.VilleAssureur);

      this.email?.setValue(res.saisieChoixServices.ResiliationAncienneAssurance?.EmailAssureur);

      this.contractNumber?.setValue(
        res.saisieChoixServices.ResiliationAncienneAssurance?.NumeroContrat
      );

      this.monthStarting =
        res.saisieChoixServices.ResiliationAncienneAssurance?.MoisDemarrageContrat;
      this.startingContract?.setValue(
        res.saisieChoixServices.ResiliationAncienneAssurance?.MoisDemarrageContrat
      );
    });
  }

  nextStep1(value: string) {
    if (value == CancellationEnum.InsurerChange) {
      this.insurerChange = true;
      this.moveIn = false;

      this.store.dispatch(
        fromService.patchService({
          payload: {
            ResiliationAncienneAssurance: {
              Raison: Raison.ChangementAssureur,
              DemenagementPlusUnAn: undefined,
              NomAssureur: '',
              AddressAssureur: '',
              CodePostalAssureur: '',
              VilleAssureur: '',
              EmailAssureur: '',
              NumeroContrat: '',
              MoisDemarrageContrat: '',
              DateEnvoiLR: '',
              isValid: false
            }
          }
        })
      );

      this.step1 = false;
      this.step2 = true;
      this.question = new QuestionVM(this.resource.question.resiliation2);
    } else {
      this.insurerChange = false;
      this.moveIn = true;

      this.store.dispatch(
        fromService.patchService({
          payload: {
            ResiliationAncienneAssurance: {
              Raison: Raison.Emmenagement,
              DemenagementPlusUnAn: undefined,
              NomAssureur: '',
              AddressAssureur: '',
              CodePostalAssureur: '',
              VilleAssureur: '',
              EmailAssureur: '',
              NumeroContrat: '',
              MoisDemarrageContrat: '',
              DateEnvoiLR: '',
              isValid: true
            }
          }
        })
      );

      this.displayMoveInModal = true;
    }
  }

  nextStep2(value: string) {
    if (value == CancellationEnum.LessOneYear) {
      this.lessOneYear = true;
      this.moreOneYear = false;

      this.displayLessOneYearModal = true;
    } else {
      this.lessOneYear = false;
      this.moreOneYear = true;
      this.displayMoreOneYearModal = true;

      this.startingContract?.clearValidators();
    }
  }

  nextStep3() {
    let date = new Date(Date.now());
    date.setDate(date.getDate() + CANCELATION_DELAY);
    this.cancellationVm.dateModal = new Date(date).toLocaleString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    });

    this.displayModal = true;
  }

  endOfWay() {
    this.moreOneYear = false;
    this.store.dispatch(
      fromService.patchService({
        payload: {
          ResAncAss: false
        }
      })
    );

    this.store.dispatch(
      fromService.changeValidationStatus({
        isValid: false
      })
    );

    this.tracker.trackEvent(
      MatomoConstants.CONTENT_INTERACTION,
      MatomoConstants.RESILIATION_CONCURRENCE_4,
      MatomoConstants.CANCEL
    );

    this.displayLessOneYearModal = false;
  }

  autoSetAdress(selectedValue: any) {
    if (selectedValue.value?.value === 'OTR') {
      this.address?.setValue('');
      this.postalCode?.setValue('');
      this.city?.setValue('');
      this.email?.setValue('');
    }
    if (selectedValue.value && this.adressesAssureurs) {
      this.address?.setValue(this.adressesAssureurs[selectedValue.value.value].address);
      this.postalCode?.setValue(this.adressesAssureurs[selectedValue.value.value].zipCode);
      this.city?.setValue(this.adressesAssureurs[selectedValue.value.value].city);
      this.email?.setValue(this.adressesAssureurs[selectedValue.value.value].email);
    }
  }

  skipStep() {
    this.store.dispatch(
      fromService.patchService({
        payload: {
          ResAncAss: false
        }
      })
    );
    this.store.dispatch(
      fromService.changeValidationStatus({
        isValid: true
      })
    );
    this.tracker.trackEvent(
      MatomoConstants.CONTENT_INTERACTION,
      MatomoConstants.RESILIATION_CONCURRENCE_1,
      MatomoConstants.JUMP_STEP
    );
    this.updateStepper(true);
    this.router.navigate(['/payment']);
  }

  validResiliation() {
    const raison = this.moveIn ? Raison.Emmenagement : Raison.ChangementAssureur;

    let insurer = this.selectedInsurance?.text ?? '';
    if (insurer == 'Autre' && this.other?.value != null) insurer = this.other?.value;

    const date = new Date();
    date.setDate(date.getDate() + 14);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);

    this.store.dispatch(
      fromService.patchService({
        payload: {
          ResAncAss: true,
          ResiliationAncienneAssurance: {
            Raison: raison,
            DemenagementPlusUnAn: this.moreOneYear,
            NomAssureur: insurer,
            AddressAssureur: this.address?.value,
            CodePostalAssureur: this.postalCode?.value,
            VilleAssureur: this.city?.value,
            EmailAssureur: this.email?.value,
            NumeroContrat: this.contractNumber?.value,
            MoisDemarrageContrat: this.startingContract?.value,
            DateEnvoiLR: date.toISOString(),
            isValid: true
          }
        }
      })
    );

    this.updateStepper(false);
    this.router.navigate(['/payment']);
  }

  confirmNextStep() {
    this.displayMoreOneYearModal = false;
    this.step2 = false;
    this.step3 = true;

    this.question = new QuestionVM(this.resource.question.resiliation3);
  }

  nextStepMoveInModal() {
    this.displayMoveInModal = false;
    this.store.dispatch(
      fromService.patchService({
        payload: {
          ResAncAss: false
        }
      })
    );
    this.store.dispatch(
      fromService.changeValidationStatus({
        isValid: false
      })
    );
    this.updateStepper(true);
    this.router.navigate(['/payment']);
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  async updateStepper(skip: boolean) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.contract
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.cancellation
    );

    if (subtitleS) {
      if (!skip) {
        subtitleS.isValid = true;
        subtitleS.isActive = true;
      } else {
        subtitleS.isValid = false;
        subtitleS.isActive = false;
        subtitleS.isVisible = false;
      }
    }

    let titleN = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.sampling
    );

    if (titleN) titleN.isActive = true;

    let subtitleNS = titleN?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.payment
    );

    if (subtitleNS) {
      subtitleNS.isActive = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  onNotFoundInsurerClick() {
    this.selectedInsurance = this.insurances.find(obj => {
      return obj.value === 'OTR';
    });

    this.insurerNotFind = false;
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }
}
